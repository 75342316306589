<template>
    <div>
       <v-row>
            <v-col>
                <v-tabs
                    v-model="tab"
                    grow
                    show-arrows
                    >
                    <v-tab
                        v-for="item in items"
                        :key="item.value" @click="handleTabInput(item)"
                    >
                        {{ item.text }}
                    </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab" touchless>
                    <v-tab-item>
                        <ranking-table/>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
       </v-row> 
    </div>
</template>

<script>
import RankingTable from '@/components/stats/rankings/RankingTable.vue'
import { mapActions } from 'vuex/dist/vuex.common.js'
export default {
  components: { RankingTable },
    props: ['mode', 'teamId'],
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Ranking Stats',
    },
    mounted(){
        if(this.mode){
            this.tab = Number(this.mode);
        }
    },
    data(){
        return {
            tab: null,
            items: [
            { text: 'Rankings Table', value: 0}
            ],
        }
    },
    methods: {
        ...mapActions(['updateOverallTeamSelections', 'resetResultFilterOptions', 'resetWinnerFilterOptions']),
        handleTabInput(item) {
            this.updateOverallTeamSelections(null);
            this.resetResultFilterOptions();
            this.resetWinnerFilterOptions();
            this.updateOverallTeamSelections([]);
            this.$router.push({
                name: `Rankings_${this.$router.currentRoute.meta.sport}`,
                params: { mode: item.value, teamId: null }
            });
        },
    }
}
</script>

<style>

</style>